import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "rad-routes-hello-world"
    }}>{`R.A.D. Routes Hello World`}</h1>
    <hr></hr>
    <p>{`A simple hello world example in R.A.D. Routes is as easy as it is in your chosen language.`}</p>
    <h2 {...{
      "id": "python-hello-world"
    }}>{`Python Hello World`}</h2>
    <hr></hr>
    <p><inlineCode parentName="p">{`Before you begin, make sure Python 3 is installed on your Operating system. Most MacOS and Linux distrobustions come bundeled with some version of Python 3.`}</inlineCode></p>
    <p>{`A quick example of how to create, save, and execute a hello world R.A.D. Route in Python 3.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`1)`}</strong>{` Go to `}<a parentName="p" {...{
            "href": "https://radroutes.com/Editor"
          }}>{`RadRoutes.com/Editor`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`2)`}</strong>{` In the top part of the editor, their will be a feild labeled 'Route', there; you will put what you want your route path to be, and if you want it to be public, or private.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`3)`}</strong>{` In the editor, select Python as the language; then type in code similar to the example below;`}</p>
      </li>
    </ul>
    <pre><code parentName="pre" {...{}}>{`print('Hello World from R.A.D. Routes')
`}</code></pre>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`4)`}</strong>{` Click submit, and your route has been saved!`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`5)`}</strong>{` You can see and edit your code at `}<a parentName="p" {...{
            "href": "https://radroutes.com/Profile"
          }}>{`RadRoutes.com/Profile`}</a></p>
      </li>
    </ul>
    <p>{`After your R.A.D. Route is saved the way you like, it will be availible to be ran by the R.A.D. Route Runner.`}</p>
    <h4 {...{
      "id": "if-you-havent-already-installed-the-rad-routes-cli-runner-you-can-find-instructions-here"
    }}>{`If you havent already installed the R.A.D. Routes CLI Runner, `}<a parentName="h4" {...{
        "href": "https://docs.radroutes.com/Prerequisites"
      }}>{`you can find instructions here`}</a></h4>
    <h2 {...{
      "id": "ruby-hello-world"
    }}>{`Ruby Hello World`}</h2>
    <hr></hr>
    <p><inlineCode parentName="p">{`Before you begin, make sure Ruby is installed on your Operating system. Most MacOS and Linux distrobustions come bundeled with some version of Ruby`}</inlineCode></p>
    <p>{`A quick example of how to create, save, and execute a hello world R.A.D. Route in Ruby.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`1)`}</strong>{` Go to `}<a parentName="p" {...{
            "href": "https://radroutes.com/Editor"
          }}>{`RadRoutes.com/Editor`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`2)`}</strong>{` In the top part of the editor, their will be a feild labeled 'Route', there; you will put what you want your route path to be, and if you want it to be public, or private.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`3)`}</strong>{` In the editor, select Ruby as the language; then type in code similar to the example below;`}</p>
      </li>
    </ul>
    <pre><code parentName="pre" {...{}}>{`puts 'Hello World from R.A.D. Routes'
`}</code></pre>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`4)`}</strong>{` Click submit, and your route has been saved!`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`5)`}</strong>{` You can see and edit your code at `}<a parentName="p" {...{
            "href": "https://radroutes.com/Profile"
          }}>{`RadRoutes.com/Profile`}</a></p>
      </li>
    </ul>
    <p>{`After your R.A.D. Route is saved the way you like, it will be availible to be ran by the R.A.D. Route Runner.`}</p>
    <h4 {...{
      "id": "if-you-havent-already-installed-the-rad-routes-cli-runner-you-can-find-instructions-here-1"
    }}>{`If you havent already installed the R.A.D. Routes CLI Runner, `}<a parentName="h4" {...{
        "href": "https://docs.radroutes.com/Prerequisites"
      }}>{`you can find instructions here`}</a></h4>
    <h2 {...{
      "id": "nodejs-hello-world"
    }}>{`Node.js Hello World`}</h2>
    <hr></hr>
    <p><inlineCode parentName="p">{`Before you begin, make sure Node.js Installed. If you do not have Node.js installed,`}</inlineCode>{` `}<a parentName="p" {...{
        "href": "https://nodejs.org/en/download/"
      }}>{`you can get the latest version from the Node.js website`}</a></p>
    <p>{`A quick example of how to create, save, and execute a hello world R.A.D. Route in Python 3.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`1)`}</strong>{` Go to `}<a parentName="p" {...{
            "href": "https://radroutes.com/Editor"
          }}>{`RadRoutes.com/Editor`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`2)`}</strong>{` In the top part of the editor, their will be a feild labeled 'Route', there; you will put what you want your route path to be, and if you want it to be public, or private.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`3)`}</strong>{` In the editor, select Node.js(JavaScript) as the language; then type in code similar to the example below;`}</p>
      </li>
    </ul>
    <pre><code parentName="pre" {...{}}>{`console.log('Hello World from R.A.D. Routes')
`}</code></pre>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`4)`}</strong>{` Click submit, and your route has been saved!`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`5)`}</strong>{` You can see and edit your code at `}<a parentName="p" {...{
            "href": "https://radroutes.com/Profile"
          }}>{`RadRoutes.com/Profile`}</a></p>
      </li>
    </ul>
    <p>{`After your R.A.D. Route is saved the way you like, it will be availible to be ran by the R.A.D. Route Runner.`}</p>
    <h4 {...{
      "id": "if-you-havent-already-installed-the-rad-routes-cli-runner-you-can-find-instructions-here-2"
    }}>{`If you havent already installed the R.A.D. Routes CLI Runner, `}<a parentName="h4" {...{
        "href": "https://docs.radroutes.com/Prerequisites"
      }}>{`you can find instructions here`}</a></h4>
    <h2 {...{
      "id": "running-a-rad-route"
    }}>{`Running A R.A.D. Route`}</h2>
    <hr></hr>
    <p>{`You can run your route by simply inputing the following command into your terminal;`}</p>
    <pre><code parentName="pre" {...{}}>{`rr -r MY_CUSTOM_ROUTE
`}</code></pre>
    <p>{`And you will see your route being executed in the terminal, if you have followed the example above, the output will be similar to the example below;`}</p>
    <pre><code parentName="pre" {...{}}>{`Hello World from R.A.D. Routes
`}</code></pre>
    <p>{`There are many other `}<a parentName="p" {...{
        "href": "https://docs.radroutes.com/RADExamples"
      }}>{`examples of useful and fun things to do with R.A.D. Routes here`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      